.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.gallery {
  width: "75%";
  align-items: "center";
  margin: "0 auto";
}
