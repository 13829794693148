:root {
  --header-height: 80px; /* Set to the height of your header */
  --navbar-header: #fff;
  --header-z-index: 1000;

  --spacer: 16px;
  --spacer-2: calc(var(--spacer) * 2);
  --spacer-4: calc(var(--spacer) * 4);

  --background-color: white;
  --hover-color: gray;
  --body-color: black;
  --link-color: black;
  --background-hover-color: #f0f0f0;
  --text-color: black;
}

/* Dark theme */
.dark-mode {
  --navbar-header: #000;
  --background-color: black;
  --hover-color: gray;
  --body-color: white;
  --link-color: white;
  --background-hover-color: #333;
  --text-color: white;
}

/* Apply background and text color globally */
body {
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-hover-color);
}
