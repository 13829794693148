.header {
  background-color: var(--navbar-header);
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: var(--header-z-index);
  align-items: center;
  justify-content: flex-start;
  padding: 0 calc(2 * var(--spacer));
  gap: var(--spacer);
}

.dark {
  background-color: black;
  color: white;
}

.dark a {
  color: white;
}

.header a {
  text-decoration: none;
  border-radius: 4px;
  padding: 8px;
}

.headerTitle {
  font-size: 48px;
  margin: 0;
}

.themeToggle {
  background: transparent;
  border: none;
  position: absolute;
  right: calc(2 * var(--spacer));
  cursor: pointer;
}

.headerLinks {
  display: flex;
  flex-direction: row;
  gap: var(--spacer);
  font-size: x-large;
  align-items: center;
  left: 0;
}
